@import "src/assets/styles/colors";

.reference-sign-theme-hide {
  reference-sign {
  }

  reference-sign[data-refsign-format="plain"] {
    @extend reference-sign;
  }

  reference-sign[data-refsign-format="number_only"] {
    @extend reference-sign;

    &:after {
      content: " " attr(data-refsign-label);
    }
  }

  reference-sign[data-refsign-format="number_in_brackets"] {
    @extend reference-sign;

    &:after {
      content: " (" attr(data-refsign-label) ")";
    }
  }

  reference-sign {
    strong {
      @extend reference-sign;
    }
  }
}